.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.login-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: #1b212c;
}

.login-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.login-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: #00F4C2;
  font-weight: 800;
}

.login-label {
  font-size: 14px;
  font-weight: 600;
  color: #00F4C2;
}

.krece-logo-content {
  align-items: center;
  justify-content: center;
  display: flex;
}

.krece-logo {
  width: 150px;
}

.login-button {
  color: #00F4C2;
  background-color: #00F4C2;
}

label {
  color: #00F4C2
}

.login-info {
  color: #00F4C2;
  align-items: center;
  justify-content: center;
  display: flex;
}

.login-link {
  color: white;
}

.login-button {
  background-color: #00F4C2;
  border-radius: 2px;
  color: black;
}

.custom-slider {
  width: 100%;
  height: 5px;
  background-color: #00F4C2;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00F4C2;
  border-radius: 50%;
  cursor: pointer;
}

.custom-slider::-moz-range-thumb {
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #00F4C2;
  border-radius: 50%;
  cursor: pointer;
}