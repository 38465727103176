.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loading-text {
    font-size: 24px;
}

.krece-logo {
    max-width: 200px;
}

.back-button {
    background-color: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
    margin-right: 20px;
    text-decoration: none;
}

.selected-store-container {
    padding: 20px;
}

.header {
    background-color: #333;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.store-details {
    display: flex;
    flex-direction: column;
}

.contact-container {
    margin-bottom: 20px;
}

.contact-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.store-locations-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.store-location-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.store-list-container {
    padding: 20px;
}

.city-filter {
    margin-bottom: 20px;
}

.store-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.store-grid-item {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px;
    /* Set a fixed width */
    height: 200px;
    /* Set a fixed height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.store-grid-item:hover {
    transform: translateY(-5px);
}

.store-name {
    margin-top: 0;
}

.store-logo {
    max-width: 100%;
    height: auto;
}